import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const TestimonialsSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulTestimonialsPage {
        testimonials {
          id
          testimonial {
            testimonial
          }
          client
          image {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const { testimonials } = data.contentfulTestimonialsPage
  const [list, setList] = useState([...testimonials.slice(0, 9)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(testimonials.length > 9)

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < testimonials.length
      const nextResults = isMore
        ? testimonials.slice(currentLength, currentLength + 9)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < testimonials.length
    setHasMore(isMore)
  }, [list]) 
  return (
    <section className="testimonial-content-sec">
      <div className="container">
        <div className="row">
          {list.map((item, i) => (
            <div className="col col-md-4" key={item.id}>
              <div className="testimonial-content-box">
              {item.image?.file?.url && <img src={item.image.file.url} alt="#" />}
                <div className="testimonial-conInner-box">
                  <svg
                    className="testimonial-conInner-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="69.564"
                    height="49.689"
                    viewBox="0 0 69.564 49.689"
                  >
                    <path
                      id="Icon_material-format-quote"
                      data-name="Icon material-format-quote"
                      d="M12.469,60.189H27.376l9.938-19.876V10.5H7.5V40.313H22.407Zm39.751,0H67.127l9.938-19.876V10.5H47.251V40.313H62.158Z"
                      transform="translate(-7.5 -10.5)"
                      fill="#4b516d"
                      opacity="0.1"
                    />
                  </svg>
                  <p>{item.testimonial.testimonial}</p>
                  <h6>{item.client}</h6>
                </div>
              </div>
            </div>
          ))}
          {hasMore && (
            <div className="col text-center">
              <a href="#">
                <button
                  type="button"
                  className="d-btn"
                  onClick={handleLoadMore}
                >
                  See More
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default TestimonialsSection
